/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LegalNoticesDto } from './LegalNoticesDto';

export type UpdateCustomerDto = {
    address1?: string;
    landlineNumber?: string;
    address2?: string;
    city?: string;
    country?: string;
    email?: string;
    firstName?: string;
    language?: UpdateCustomerDto.language;
    lastName?: string;
    consentedMarketing?: boolean;
    legalNotices?: LegalNoticesDto;
    password?: string;
    phoneNumber?: string;
    pictureId?: string;
    postalCode?: string;
    refusedEmail?: boolean;
    state?: string;
    didInstallApp?: boolean;
    isInWheelchair?: boolean;
};

export namespace UpdateCustomerDto {

    export enum language {
        EN = 'en',
        DE = 'de',
    }


}

