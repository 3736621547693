/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DoctorModel } from './DoctorModel';
import type { FileModel } from './FileModel';
import type { WeeklySchedule } from './WeeklySchedule';

export type ClinicModel = {
    name: string;
    createdAt: string;
    status: ClinicModel.status;
    pictureId: string;
    longitude: string;
    latitude: string;
    address: string;
    mapUrl: string;
    tZone: string;
    email: string;
    phoneNumber: string;
    slug?: string;
    weeklySchedule: WeeklySchedule;
    acceptsCash: boolean;
    picture: FileModel;
    doctors: Array<DoctorModel>;
    isTelemedicine: boolean;
    id: string;
};

export namespace ClinicModel {

    export enum status {
        INACTIVE = 'inactive',
        ACTIVE = 'active',
        PENDING = 'pending',
    }


}

