/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LockPetDto = {
    petId: string;
    visitReason: LockPetDto.visitReason;
    medicalEventIds: Array<string>;
};

export namespace LockPetDto {

    export enum visitReason {
        GENERAL = 'general',
        WELCOME = 'welcome',
        ILLNESS = 'illness',
        GENERAL_AND_ILLNESS = 'general_and_illness',
        GENERAL_FIRST_VISIT = 'general_first_visit',
    }


}

