/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentModel } from './AppointmentModel';
import type { ClinicModel } from './ClinicModel';
import type { DeviceDetailsDto } from './DeviceDetailsDto';
import type { PetModel } from './PetModel';

export type CustomerModel = {
    createdAt: string;
    updatedAt: string;
    phoneNumber: string | null;
    landlineNumber: string | null;
    email: string | null;
    password: string;
    role: CustomerModel.role;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    preferredDocId: string;
    preferredClinicId: string | null;
    language: CustomerModel.language;
    wpData: any;
    pictureId: string;
    new: boolean;
    extra: any;
    address1: string | null;
    address2: string | null;
    profileHash: string;
    tempToken: string;
    refusedEmail: boolean;
    firstName: string;
    lastName: string;
    source: CustomerModel.source;
    marketingConsentStatus: CustomerModel.marketingConsentStatus;
    isActive: boolean;
    inactivatedAt: string | null;
    didInstallApp: boolean;
    installedAppAt: string;
    isOffender: boolean;
    offendingPeriodStartedAt: string;
    anonymisedAt: string | null;
    deviceDetails: DeviceDetailsDto;
    notes?: string;
    hasSpecialNeeds: boolean;
    isInWheelchair: boolean;
    pets: Array<PetModel>;
    prefferedClinic: ClinicModel | null;
    appointments: Array<AppointmentModel>;
    consentedMarketing: boolean;
    canSetPassword: boolean;
    isAnonymised: boolean;
    id: string;
};

export namespace CustomerModel {

    export enum role {
        GOD = 'god',
        MANAGER = 'manager',
        CUSTOMER = 'customer',
        ANONYMOUS = 'anonymous',
        DOC = 'doc',
        ASSISTANT = 'assistant',
    }

    export enum language {
        EN = 'en',
        DE = 'de',
    }

    export enum source {
        MYREX = 'myrex',
        REXOS = 'rexos',
        WALKIN = 'walkin',
        MYREX_WALKIN = 'myrex_walkin',
        REXAPP = 'rexapp',
        REXAPP_WALKIN = 'rexapp_walkin',
    }

    export enum marketingConsentStatus {
        NO = 'no',
        YES = 'yes',
        PENDING = 'pending',
    }


}

