import { AppointmentChangeLog } from 'service/api/generated/models/AppointmentChangeLog';
import React, { useMemo } from 'react';
import moment from 'moment';
import { ArrowRightOutlined, FieldTimeOutlined } from '@ant-design/icons';

import styles from './AppointmentChangeLogs.module.scss';
import { getFormatedProperty } from '../AppointmentForm/utils/changeLogHelper';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { AppointmentModel, TaskChangeLogModel } from 'service/api';

interface AppointmentChangeLogsProps {
  wasDoctorSelected?: boolean;
  source?: AppointmentModel['source'];
  changeLogs: Array<Array<AppointmentChangeLog | TaskChangeLogModel>>;
}

export const AppointmentChangeLogs = ({
  changeLogs,
  wasDoctorSelected,
  source,
}: AppointmentChangeLogsProps) => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.resolvedLanguage;

  const lastChangeDate = useMemo(() => {
    const log = changeLogs[0] && changeLogs[0][0];
    return log
      ? `${moment(log.createdAt).format('DD.MM.YYYY')}, ${moment(
          log.createdAt,
        ).format('HH:mm')}`
      : '';
  }, [changeLogs]);

  const headerTitle = useMemo(() => {
    return `${lastChangeDate}`;
  }, [lastChangeDate, source]);

  return (
    <Collapse expandIconPosition="end">
      <Collapse.Panel header={headerTitle} key="logs">
        {changeLogs.map((_changeLogs, i) => {
          return (
            <div>
              <div className={styles.title}>
                <div className="mr-3.5 font-bold">
                  {_changeLogs[0]?.user
                    ? `${_changeLogs[0].user.firstName} ${_changeLogs[0].user.lastName}`
                    : t((_changeLogs[0] as AppointmentChangeLog).actor) || ''}
                </div>

                <div className="text-gray-400">
                  <FieldTimeOutlined className="mr-1.5" />
                  {moment(_changeLogs[0].createdAt).format('DD.MM.YYYY')}
                  {', '} {moment(_changeLogs[0].createdAt).format('HH:mm')}
                </div>
              </div>
              <div>
                {_changeLogs.map((log) => {
                  return (
                    <span>
                      <b className="font-bold">{t(log.property)}</b>:{' '}
                      {getFormatedProperty(
                        log.oldValue,
                        log.property,
                        currentLanguage,
                      )}
                      <span className="text-gray-400 px-1.5">
                        <ArrowRightOutlined />
                      </span>
                      {getFormatedProperty(
                        log.newValue,
                        log.property,
                        currentLanguage,
                      )}
                      <br />
                      {i === changeLogs.length - 1 && wasDoctorSelected
                        ? t('preferred_doctor_selected')
                        : null}
                    </span>
                  );
                })}
              </div>
              <br />
            </div>
          );
        })}
      </Collapse.Panel>
    </Collapse>
  );
};
